import React, { useState } from 'react'

const Todos = () => {
  const [todos, setTodos] = useState([])
  const [newTodo, setNewTodo] = useState('')

  const handleDeleteTodo = (id) => {
    const newTodo = todos.filter((todo) => todo.id !== id)
    setTodos(newTodo)
  }

  const generateUuid = () => {
    let chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('')
    for (let i = 0, len = chars.length; i < len; i++) {
      switch (chars[i]) {
        case 'x':
          chars[i] = Math.floor(Math.random() * 16).toString(16)
          break
        case 'y':
          chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16)
          break
        default:
          break
      }
    }
    return chars.join('')
  }

  return (
    <div>
      <h2>TODO LIST</h2>
      <input
        type="text"
        placeholder="your todo..."
        value={newTodo}
        onChange={(e) => setNewTodo(e.target.value)}
      />
      <button
        onClick={() =>
          setTodos([
            { id: generateUuid(), title: newTodo, date: new Date() },
            ...todos,
          ])
        }
      >
        add
      </button>

      <ul>
        {todos.map((todo, key) => {
          return (
            <li key={key}>
              {todo.title} - {String(todo.date)}{' '}
              <button onClick={() => handleDeleteTodo(todo.id)}>delete</button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Todos
