import './App.css'
import Todos from './components/todos'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>todos.tsuji.dev</h1>
      </header>

      <Todos />
    </div>
  )
}

export default App
